.view-resume-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-resume-button:hover {
    background-color: #0056b3;
  }
  
  .resume-container {
    margin-top: 20px;
  }
  